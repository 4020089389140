import * as React from 'react';
import styled from 'styled-components';
import BrandTriangles from '../../images/icons/brand-triangles.svg';

interface IBrandedTitleProps {
    className?: string;
}

export const BrandedTitle: React.FC<IBrandedTitleProps> = (props) => {
    return (
        <StyledTitle className={props.className ? `${props.className} branded-title` : 'branded-title'}>
            <StyledTriangles />
            {props.children}
        </StyledTitle>
    );
}

const StyledTitle = styled.h2`
    position: relative;
    width: max-content;
    margin-left: auto !important;
    margin-right: auto !important;
    font-family: ${props => props.theme.fonts.din};
    font-weight: 400;
    color: ${props => props.theme.colors.nearlyNavy};
`;

const StyledTriangles = styled(BrandTriangles)`
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(75%, -50%);
    width: 1em;
    height: 0.5em;
`;